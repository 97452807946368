import * as z from "zod";
import { parseISO } from "date-fns";

import { RegexMoney } from "@fwa/src/utils/formUtils";

import {
  type FundraisingPageType,
  type FundraisingPageTypeType,
  type SuperTeamPageType,
  type TeamPageType,
} from "@fwa/src/types";

export const DISPLAY_NAME_MAX_LENGTH = 200;
export const PAGE_TITLE_MAX_LENGTH = 100;
export const PAGE_SUBTITLE_MAX_LENGTH = 200;
export const PAGE_URL_MAX_LENGTH = 120;
export const ACTIVITY_IN_MEMORY_MAX_LENGTH = 100;
export const PAGE_STORY_MAX_LENGTH = 10_000;

// base page shape
export const fundraisingPageValidation = z.object({
  title: z
    .string({ required_error: "Your page name is required." })
    .trim()
    .min(1, "Your page name is required.")
    .min(3, "Your page name should be at least 3 characters.")
    .max(
      PAGE_TITLE_MAX_LENGTH,
      `Your page name should be at most ${PAGE_TITLE_MAX_LENGTH} characters.`,
    )
    .regex(
      /^((?!\s\s)[\s\S])*$/,
      "Your page name should not contain adjacent spaces.",
    ),
  subtitle: z
    .string()
    .trim()
    .max(
      PAGE_SUBTITLE_MAX_LENGTH,
      `Your mission statement should be at most ${PAGE_SUBTITLE_MAX_LENGTH} characters.`,
    )
    .optional(),
  story: z
    .string()
    .trim()
    .max(
      PAGE_STORY_MAX_LENGTH,
      `Your story should be at most ${PAGE_STORY_MAX_LENGTH} characters.`,
    )
    .optional(),
  url: z
    .string({ required_error: "Your page link is required." })
    .trim()
    .min(1, "Your page link is required.")
    .min(3, "Your page link should be at least 3 characters.")
    .max(
      PAGE_URL_MAX_LENGTH,
      `Your page link should be at most ${PAGE_URL_MAX_LENGTH} characters.`,
    )
    .regex(
      /^[a-z0-9]((?!--)[a-z0-9-])*[a-z0-9]$/,
      "Your page link should only contain lowercase letters (a-z), numbers (0-9) and single hyphens (-), and must not start or end with a hyphen.",
    ),
  displayName: z
    .string()
    .trim()
    .min(2, "Your display name should be at least 2 characters long.")
    .max(
      DISPLAY_NAME_MAX_LENGTH,
      `Display name should be at most ${DISPLAY_NAME_MAX_LENGTH} characters long.`,
    )
    .or(z.literal("")),
  activityName: z
    .string()
    .trim()
    .max(35, "Activity description should be at most 35 characters long.")
    .regex(
      /^(&#039;|[a-zA-Z0-9\s,'&-]|&amp;)*$/,
      "Activity description should only contain letters, hyphens, spaces, apostrophes, commas and ampersands.",
    )
    .optional(),
  activityDateTimePartial: z.string().trim().optional(),
  activityDateTimeEndPartial: z.string().trim().optional(),
  activityInMemoryMoreInfo: z.boolean().optional(),
  activityDateTime: z
    .date()
    .min(
      new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
      "Activity start date should be less than 10 years in the past.",
    )
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
      "Activity start date should be less than 10 years in the future.",
    )
    .optional(),
  activityDateTimeEnd: z
    .date()
    .min(
      new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
      "Activity end date should be less than 10 years in the past.",
    )
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
      "Activity end date should be less than 10 years in the future.",
    )
    .optional(),
  target: z
    .string()
    .trim()
    .regex(RegexMoney.pattern, RegexMoney.message)
    .refine((value) => (value ? Number(value) >= 0 : true), {
      message: "Please enter an amount between 0 and 1000000 inclusive.",
    })
    .refine((value) => (value ? Number(value) <= 1_000_000 : true), {
      message: "Please enter an amount between 0 and 1000000 inclusive.",
    })
    .optional(),
  activityInMemory: z
    .string()
    .trim()
    .max(100, "In memory name should be at most 100 characters long.")
    .optional(),
  dateOfBirthDeceasedPartial: z.string().trim().optional(),
  dateOfDeathDeceasedPartial: z.string().trim().optional(),
  dateOfBirthDeceased: z
    .date()
    .min(parseISO("1753-01-01"), "All dates must be after the year 1752.")
    .max(new Date(), "All dates must be in the past.")
    .optional(),
  dateOfDeathDeceased: z
    .date()
    .min(parseISO("1753-01-01"), "All dates must be after the year 1752.")
    .max(new Date(), "All dates must be in the past.")
    .optional(),
});
// create page validations

export const createPageActivityValidation = fundraisingPageValidation
  .pick({
    activityName: true,
    activityDateTimePartial: true,
    activityDateTimeEndPartial: true,
    activityInMemoryMoreInfo: true,
    activityDateTime: true,
    activityDateTimeEnd: true,
  })
  .refine(
    (data) =>
      data.activityDateTimePartial?.length ? data.activityDateTime : true,
    {
      message: "Please enter a valid date with DD MM YYYY.",
      path: ["activityDateTime"],
    },
  )
  .refine(
    (data) =>
      data.activityDateTimeEndPartial?.length ? data.activityDateTimeEnd : true,
    {
      message: "Please enter a valid date with DD MM YYYY.",
      path: ["activityDateTimeEnd"],
    },
  )
  .refine((data) => (data.activityDateTimeEnd ? data.activityDateTime : true), {
    message: "Activity start date is required if activity end date is set.",
    path: ["activityDateTime"],
  })
  .refine(
    (data) =>
      data.activityDateTimeEnd && data.activityDateTime
        ? data.activityDateTimeEnd >= data.activityDateTime
        : true,
    {
      message: "Activity end date must be after activity start date.",
      path: ["activityDateTimeEnd"],
    },
  );

export const createPageMotivationValidation = fundraisingPageValidation
  .pick({
    target: true,
    activityInMemory: true,
    dateOfBirthDeceasedPartial: true,
    dateOfDeathDeceasedPartial: true,
    dateOfBirthDeceased: true,
    dateOfDeathDeceased: true,
  })
  .refine(
    (data) =>
      data.dateOfBirthDeceasedPartial?.length ? data.dateOfBirthDeceased : true,
    {
      message: "Please enter a valid date with DD MM YYYY.",
      path: ["dateOfBirthDeceased"],
    },
  )
  .refine(
    (data) =>
      data.dateOfDeathDeceasedPartial?.length ? data.dateOfDeathDeceased : true,
    {
      message: "Please enter a valid date with DD MM YYYY.",
      path: ["dateOfDeathDeceased"],
    },
  )
  .refine(
    (data) =>
      data.dateOfBirthDeceased && data.dateOfDeathDeceased
        ? data.dateOfBirthDeceased <= data.dateOfDeathDeceased
        : true,
    {
      message:
        "The date of birth must be the same as or before the date of passing.",
      path: ["dateOfDeathDeceased"],
    },
  );

export const createPagePageValidation = fundraisingPageValidation.pick({
  title: true,
  url: true,
});

export type FormStateFromUrl = {
  currentPagesForEvent: FundraisingPageType[];
  fundraisingPageType?: FundraisingPageTypeType;
  team?: TeamPageType;
  teamTitle: string;
  superTeam?: SuperTeamPageType;
  eventCode: string;
  eventName?: string;
  activityType: string;
  activityMotivation: string;
  fundraisingPageTypeSearchData: FundraisingPageTypeType[];
  fundraisingPageTypeSearchString: string;
  activityResCode: string;
};

// lets infer as much as possible from the validation schema for the form types
export type CreatePageFormValues = z.infer<typeof createPagePageValidation> &
  z.infer<typeof createPageMotivationValidation> &
  z.infer<typeof createPageActivityValidation> &
  FormStateFromUrl;
// edit in place validation

export const fundraisingPageDisplayNameFormValidation =
  fundraisingPageValidation.pick({ displayName: true });

export const validateDisplayName = (text: string): string | undefined => {
  const validationResult = fundraisingPageDisplayNameFormValidation.safeParse({
    displayName: text,
  });
  if (validationResult.success) return undefined;
  else {
    return validationResult.error.issues[0].message;
  }
};

// export const pageTitleValidation = fundraisingPageValidation.pick({
//   title: true,
// });

// export const validatePageTitle = (text: string): string | undefined => {
//   const validationResult = pageTitleValidation.safeParse({ title: text });
//   if (validationResult.success) return undefined;
//   else {
//     return validationResult.error.issues[0].message;
//   }
// };

export const pageUrlValidation = fundraisingPageValidation.pick({
  url: true,
});

export const validatePageUrl = (text: string): string | undefined => {
  const validationResult = pageUrlValidation.safeParse({ url: text });

  if (validationResult.success) return undefined;
  else {
    return validationResult.error.issues[0].message;
  }
};

export const fundraisingPageTargetValidation = fundraisingPageValidation.pick({
  target: true,
});

export const validatePageTarget = (text: string): string | undefined => {
  const validationResult = fundraisingPageTargetValidation.safeParse({
    target: text,
  });

  if (validationResult.success) {
    return undefined;
  } else {
    return validationResult.error.issues[0].message;
  }
};

export const fundraisingPageTitleValidation = fundraisingPageValidation.pick({
  title: true,
});

export const validatePageTitle = (text: string): string | undefined => {
  const validationResult = fundraisingPageTitleValidation.safeParse({
    title: text,
  });

  if (validationResult.success) {
    return undefined;
  } else {
    return validationResult.error.issues[0].message;
  }
};

export const fundraisingPageSubtitleValidation = fundraisingPageValidation.pick(
  {
    subtitle: true,
  },
);

export const validatePageSubtitle = (text: string): string | undefined => {
  const validationResult = fundraisingPageSubtitleValidation.safeParse({
    subtitle: text,
  });

  if (validationResult.success) {
    return undefined;
  } else {
    return validationResult.error.issues[0].message;
  }
};

export const fundraisingPageStoryValidation = fundraisingPageValidation.pick({
  story: true,
});

export const validatePageStory = (text: string): string | undefined => {
  const validationResult = fundraisingPageStoryValidation.safeParse({
    subtitle: text,
  });

  if (validationResult.success) {
    return undefined;
  } else {
    return validationResult.error.issues[0].message;
  }
};

export const fundraisingPageActivityDateValidation = fundraisingPageValidation
  .pick({
    activityDateTimePartial: true,
    activityDateTimeEndPartial: true,
    activityInMemoryMoreInfo: true,
    activityDateTime: true,
    activityDateTimeEnd: true,
  })
  .refine(
    (data) =>
      data.activityDateTimePartial?.length ? data.activityDateTime : true,
    {
      message: "Please enter a valid date with DD MM YYYY.",
      path: ["activityDateTime"],
    },
  )
  .refine(
    (data) =>
      data.activityDateTimeEndPartial?.length ? data.activityDateTimeEnd : true,
    {
      message: "Please enter a valid date with DD MM YYYY.",
      path: ["activityDateTimeEnd"],
    },
  )
  .refine((data) => (data.activityDateTimeEnd ? data.activityDateTime : true), {
    message: "Activity start date is required if activity end date is set.",
    path: ["activityDateTime"],
  })
  .refine(
    (data) =>
      data.activityDateTimeEnd && data.activityDateTime
        ? data.activityDateTimeEnd >= data.activityDateTime
        : true,
    {
      message: "Activity end date must be after activity start date.",
      path: ["activityDateTimeEnd"],
    },
  );

export const fundraisingPageActivityInMemoryValidation =
  fundraisingPageValidation
    .pick({
      activityInMemory: true,
      dateOfBirthDeceased: true,
      dateOfDeathDeceased: true,
      dateOfBirthDeceasedPartial: true,
      dateOfDeathDeceasedPartial: true,
    })
    .refine(
      (data) =>
        data.dateOfBirthDeceasedPartial?.length
          ? data.dateOfBirthDeceased
          : true,
      {
        message: "Please enter a valid date with DD MM YYYY.",
        path: ["dateOfBirthDeceased"],
      },
    )
    .refine(
      (data) =>
        data.dateOfDeathDeceasedPartial?.length
          ? data.dateOfDeathDeceased
          : true,
      {
        message: "Please enter a valid date with DD MM YYYY.",
        path: ["dateOfDeathDeceased"],
      },
    )
    .refine(
      (data) =>
        data.dateOfBirthDeceased && data.dateOfDeathDeceased
          ? data.dateOfBirthDeceased <= data.dateOfDeathDeceased
          : true,
      {
        message:
          "The date of birth must be the same as or before the date of passing.",
        path: ["dateOfDeathDeceased"],
      },
    );

export type InMemoryFormValues = z.infer<
  typeof fundraisingPageActivityInMemoryValidation
>;
