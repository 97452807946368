export const secondsToDurationString = (seconds: number) => {
  const duration = Number(seconds);
  const h = Math.floor(duration / 3600);
  const m = Math.floor((duration % 3600) / 60);
  const s = Math.floor((duration % 3600) % 60);
  const hDisplay = h ? `${h}h` : "";
  const mDisplay = m ? `${m}m` : "";
  const sDisplay = s ? `${s}s` : "";
  return [hDisplay, mDisplay, sDisplay].join(" ").replace("  ", " ").trim();
};

export const isPast = (date: Date): boolean => {
  const now = new Date().getTime();
  const time = date.getTime();
  return time < now;
};

export const isFuture = (date: Date): boolean => {
  const now = new Date().getTime();
  const time = date.getTime();
  return time > now;
};

export const isToday = (date: Date | null) => {
  if (!date) {
    return false;
  }
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isTomorrow = (date: Date | null) => {
  if (!date) {
    return false;
  }
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
};

export const isValidDate = (d: Date) =>
  d instanceof Date && !Number.isNaN(d.getTime());

export const durationFromDateStrings = (
  startDateString?: string | null,
  endDateString?: string | null,
) =>
  startDateString?.length && endDateString?.length
    ? durationFromDates(new Date(startDateString), new Date(endDateString))
    : 0;

export const durationFromDates = (
  startDate?: Date | null,
  endDate?: Date | null,
) =>
  startDate && endDate
    ? Math.round(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      ) + 1
    : 0;

export const getMiddayAtomTimeStringFromDate = (date?: Date) => {
  if (!date) {
    return "";
  }
  const currYear = date.getFullYear();
  const currMonth = date.getMonth();
  const currDate = date.getDate();

  const dateString = new Date(currYear, currMonth, currDate, 12)
    .toISOString()
    .split("T")[0];

  const utcMiddayTimeString = `${dateString}T12:00:00+00:00`;
  return utcMiddayTimeString;
};
